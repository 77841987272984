 .age-gate-overlay-container {
   width: 100vw;  
   height: 100vh;
   background-color: rgba(0, 0, 0, 0.8);
   overflow: hidden;
   display: flex;
   justify-content: center;
   position: fixed;
   top: 0;
   left: 0;
   z-index: 101;
      
   .content-container {
      
      display: flex;
      flex-direction: column;      
      justify-content: center;
      align-items: center;

      .logo {
         width: 40%;
         height: auto;             
         margin-bottom: 2em;     
      }

      p {
         font-family: 'HypermarketExpW00', sans-serif;
         font-size: 1.5em;
         color: #ff00ce;
         text-align: center;
         width: 60%;
         margin: 0;     
      }

      .button-container {         
         display: flex;
         flex-direction: row;
         gap: 5vw;
         
         button {
            width: 8vw;
            height: auto;
            position: relative;            
            cursor: pointer;
   
            img {
               width: 100%;
               height: auto;
            }
   
            .circle {
               position: absolute;
               top: 0;
               left: 0;
               display: none;
            }

            &:hover .circle {
               display: initial;
            }
         }

         .confirm-button {
            width: 10vw;
         }
      }

   }

 }

    //phone & small tablet portrait
@media screen and (max-width: 47.5em) {

   .age-gate-overlay-container {
      .content-container {

         .button-container {

            button {
               width: 25vw;
            }

            .confirm-button {
               width: 30vw;
            }
         }         
      }
   }

}