 .main-overlay-container {
   width: 100vw;
   height: 100vh;    
   background-color: transparent;
   display: flex;
   justify-content: center;
   position: fixed;
   z-index: 100;
   pointer-events: none;

   .faq-button {
      position: absolute;
      top: 20px;
      right: 20px;
      width: 60px;
      height: auto;
      cursor: pointer;
      pointer-events: all;
      display: block;
      
      img.active {
         display: none;
      }

      img {
         width: 100%;
         height: auto;
      }
      
      &:hover, &.active {

         img {
            display: none;
         }

         img.active {
            display: initial;
         }
      }
   }

   .preorder-button {
      position: absolute;
      top: 20px;
      left: 20px;
      cursor: pointer;
      pointer-events: all;
      text-decoration: none;
      
      p {
         font-size: 20px;
         color: #ff00ce;
         font-family: 'HypermarketExpW00', sans-serif;
         margin: 0;
      }

      &:hover p {
         color: #ff9be7;
      }
   }   

   .social-container {
      display: flex;
      flex-direction: column;
      gap: 25px;
      align-self: center;
      margin-left: auto;
      margin-right: 40px;

      .social-button {
         width: 40px;
         height: 40px;
         flex-wrap: wrap;
         pointer-events: all;
         
         img.active {
            display: none;
         }

         img {
            width: 100%;
            height: auto;
         }

         &:hover, &.active {

            img {
               display: none;
            }
   
            img.active {
               display: initial;
            }
         }         
      }

   }

 }

//phone & small tablet portrait
@media screen and (max-width: 47.5em) {
   .main-overlay-container {
      .social-container {
         display: none;
      }
   }
}