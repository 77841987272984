 .footer {
   width: 100vw;   
   overflow: hidden;
   display: flex;
   align-items: center;
   justify-content: center;
   background-color: #000;
   padding: 5vh 0;
   gap: 2em;

   a {

      display: flex;
      text-decoration: none;
      p {
         font-size: 1.5em;   
         font-family: 'HypermarketExpW00', sans-serif;
         color: #ff00ce;              
      }

      &:hover p {
         color: #ff9be7;
      }
   }

 }

  //phone & small tablet portrait
@media screen and (max-width: 47.5em) {
   .footer {
      a p {
         font-size: 1em;
      }
   }
 }