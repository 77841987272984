 .loader-container {
   width: 100vw;   
   height: 100vh;
   overflow: hidden;
   display: flex;
   align-items: center;
   justify-content: center;
   background-color: #000;
   position: fixed;
   top: 0;
   left: 0;
   z-index: 1000;

   p {
      font-size: 1.5em;   
      font-family: 'HypermarketExpW00', sans-serif;
      color: #ff00ce;              
      text-align: center;
   }



 }