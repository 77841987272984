@font-face {
  font-family: 'HypermarketExpW00';
  src: url('fonts/HypermarketExpW00-Regular.woff2') format('woff2'),
      url('fonts/HypermarketExpW00-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MollieRocky';
  src: url('fonts/MollieRockyMedium.woff2') format('woff2'),
      url('fonts/MollieRockyMedium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Creattion';
  src: url('fonts/Creattion.woff2') format('woff2'),
      url('fonts/Creattion.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

* {
  z-index: 1;
  position: relative;
}

html, body {
  overscroll-behavior-y: none;
}

body {
  margin: 0;
  font-family: 'HypermarketExpW00', sans-serif;
  color: #ff00ce;    
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

main {
  min-height: 100vh;
  min-width: 100vw;
  height: auto;
  width: auto;
}

.terms {
  padding: 10vh 10vw;
}

.terms .divider {
  height: 1px;
  background: #ff00ce;
  width: 100%;
}

button {
  background: none;
  border: none;  
}