.gallery-slider-container {
  align-self: center;
  width: 90vw;
  margin: 2em 0;
  //height: 50vh;
  
  .images-wrap div {
    display: flex !important;
    justify-content: center;
  }
  
  img {
    width: 20vw;
    height: auto;
    object-fit: contain;
    border-radius: 45% 45% 0 0;
    align-self: center;
  }

 }

@media screen and (max-width: 76.25em) {
  .gallery-slider-container {
    
    img {
      width: 28vw;
    }
  
   }
  
}

@media screen and (max-width: 52.5em) {
  .gallery-slider-container {
    
    img {
      width: 40vw;
    }
  
   }
  
}

@media screen and (max-width: 28.5em) {
  .gallery-slider-container {
    
    img {
      width: 80vw;
    }
  
   }
  
}