 .section-5-container {
    width: 100vw;
    height: 100vh;    
    position: relative;
    background-color: #000;
    overflow: hidden;

   .get-close-container {
      width: 100%;
      height: 100%;
      
      .indulge-logo-left, .indulge-logo-right {
         width: 73%;
         height: auto;
         // display: flex;
         // align-self: baseline;
         // margin: 0 auto;         
         position: absolute;
         bottom: 50%;
         left: 50%;
         transform: translate(-50%, 11%);      
      }

      .indulge-logo-right {
         display: none;
      }
   }


   .button-container {
      width: 100%;
      height: 50%;
      
      position: absolute;
      top: 0;
      left: 0;

      display: flex;
      justify-content: center;
      gap: 30vw;

      a {
         width: 9.6%;
         height: 6%;
         margin-top: 20%;
         align-self: flex-end;
         transform: translate(0%, -500%); 
         position: relative;
         cursor: pointer;

         img {
            width: 100%;
            height: auto;
         }

         .circle {
            position: absolute;
            top: 0;
            left: 0;
            display: none;
         }

         &:hover .circle {
            display: initial;
         }
      }
      
   }

   .instructions {
      font-size: 1em;
      width: 25%;
      position: absolute;
      top: 40px;
      left: 15%;
      font-family: 'HypermarketExpW00', sans-serif;
      color: #ff00ce;      
   }

 }

//phone & small tablet portrait
@media screen and (max-width: 47.5em) {

   .section-5-container {
      .get-close-container {

         .indulge-logo-left, .indulge-logo-right {
            width: 90%;   
         }

         .button-container {
            width: 100%;
            height: 50%;
            
            position: absolute;
            top: 0;
            left: 0;
      
            display: flex;
            justify-content: center;
            gap: 30vw;
      
            a {
               width: 28.8%;
               height: 18%;
               transform: translate(0%, -50%); 
            }
         }         
      }

      .instructions {
         font-size: 1em;
         width: 80%;
         left: 10%;
         position: absolute;
         bottom: 20px;
         top: initial;         
         font-family: 'HypermarketExpW00', sans-serif;
         color: #ff00ce;      
      }      
   }

}