 .video-background-container {
   width: 100vw;
   height: 100vh;    
   position: fixed;
   display: flex;
   top: 0;
   left: 0;
   aspect-ratio: 1.38 / 1;
   z-index: 1;
   align-items: center;
   justify-content: center;

   h1 {
    font-family: 'MollieRocky', serif;
    font-size: 10vw;
    line-height: 1em;
    text-align: center;    
    opacity: 0;
    transform: translateY(-10%);
   }

   #video-background {
      height: 100%;
      width: 100%;        
      object-fit: cover;  
      position: absolute;
      top: 0;
      left: 0;
   }    

 }