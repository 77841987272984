 .section-3-container {
   width: 100vw;
   min-height: 100vh;    
   background-color: #000;

   display: flex;
   overflow: hidden;

   .left-container {
      width: 50vw;
      height: 100vh;

      .wine-container {
         width: 100%;
         height: 100%;
         display: flex;
         justify-content: center;
         position: relative;
         scale: 0;         

         img {
            height: auto;
            width: 100%;
            object-fit: contain;
         }         
      }

      .bottle {
         width: 80%;
         height: auto;        
         object-fit: contain;
         z-index: 10;
         display: flex;
         justify-content: center;         

         animation: bounce 1.5s infinite;            
         animation-direction: alternate;
         animation-timing-function: ease-in-out;             
      }

      .grape-1 {
         position: absolute;
         top: 30%;
         left: 40%;
         width: 12%;
         height: auto;
      }

      .grape-2 {
         position: absolute;
         top: 32%;
         left: 55%;
         z-index: 12;
         width: 20%;
         height: auto;         
      }
      
      .grape-3 {
         position: absolute;
         top: 50%;
         left: 22%;
         width: 20%;
         height: auto;         
      }
      
      .grape-4 {
         position: absolute;
         top: 55%;
         left: 59%;
         width: 12%;
         height: auto;
         z-index: 12;
      }
      
      .grape-5 {
         position: absolute;
         top: 70%;
         left: 30%;
         width: 12%;
         height: auto;
         z-index: 12;
      }      
   }

   .right-container {
      width: 30vw;
      display: flex;
      flex-direction: column;
      justify-content: center;

      p {
         font-family: 'HypermarketExpW00', sans-serif;
         font-size: 1em;
         color: #ff00ce;
      }

      .logo {
         width: 55%;
         align-self: center;
      }

      .price {
         width: 30%;
         align-self: center;
      }

      .preorder-button {
         cursor: pointer;
         pointer-events: all;
         text-decoration: none;
         
         p {
            font-size: 2.5em;
            color: #ff00ce;
            font-family: 'HypermarketExpW00', sans-serif;
            margin: 1em 0;            
            margin-top: 0;
            text-align: center;
         }
   
         &:hover p {
            color: #ff9be7;
         }
      }         
   }

 }

 //phone & small tablet portrait
@media screen and (max-width: 47.5em) {

   .section-3-container {
      flex-direction: column;

      .left-container {
         width: 100vw;
         height: auto;
         margin-top: 10vh;

         .bottle {
            width: 60%;
         }
      }

      .right-container {
         width: 100vw;
         height: auto;
         padding: 0 15vw;
         box-sizing: border-box;            
         margin-bottom: 5vh;

         .price {
            width: 60%;
         }

         .preorder-button {

            p {
               margin: 0;            
            }

         }            
      }
   }

}

@keyframes bounce {
   0% { transform: translateY(-1%); }
   100% { transform: translateY(1%); }
}