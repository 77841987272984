 .faq-container {
   width: 100vw;
   height: 100vh;    
   position: relative;
   background-color: #000;
   display: flex;
   overflow: hidden;

   .faq-title {
      width: 50%;
      height: auto;
      display: flex;
      align-self: baseline;
      transform: translateX(20%);            
   }

   .text-container {
      width: 22%;
      display: flex;
      flex-direction: column;
      gap: 1em;
      margin-left: auto;
      margin-right: 10%;
      justify-content: center;      

      img {
        width: 75%;
        height: auto;
      }

      p {
          font-size: 1.2em;
          color: #FFFFFF;
          font-family: 'HypermarketExpW00', sans-serif;
          margin: 0;
          text-transform: uppercase;
      } 
   }

   .bottom-logo {
      position: absolute;
      bottom: 5%;
      left: 50%;
      transform: translateX(-50%);
      width: 15%;

      img {
        width: 100%;
        height: auto;        
      }

   }
 }

 @media screen and (max-width: 81em) {
  .faq-container {

    .faq-title {
        width: 45%;
        transform: translateX(10%);            
    }

   .text-container {
      width: 30%;   

      p {
        font-size: 1.1em;
      }
   }
  }
}

 //phone & small tablet portrait
@media screen and (max-width: 56.5em) {
  .faq-container {
    flex-direction: column;
    height: auto;
    align-items: center;

   .faq-title {
      width: 70%;
      transform: translateX(20%);            
   }

   .text-container {
      width: 70%;
      display: flex;
      flex-direction: column;
      gap: 1em;
      justify-content: center;      
      margin: 2em 0;


      img {
        width: 75%;
        height: auto;
      }

      p {
          font-size: 1.2em;
      } 
   }

   .bottom-logo {
      position: initial;
      transform: translateX(0%);
      margin-bottom: 2em;
      width: 50%;

      img {
        width: 100%;
        height: auto;        
      }
  }   
  }
}