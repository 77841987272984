 .section-1-container {
   width: 100vw;
   height: 100vh;    
   position: relative;
   background-color: #000;
   display: flex;
   justify-content: center;
   overflow: hidden;

   .title-leg {
      width: 26%;
      height: auto;
      position: absolute;
      bottom: 50%;            
      transform: translate(4%, -100%);           
      z-index: 10;
   }

   .title-left {
      width: 32%;
      height: auto;
      position: absolute;
      bottom: 50%;      
      transform: translate(-220%, 46%);  
   }

   .title-right {
      width: 30%;
      height: auto;
      position: absolute;
      bottom: 50%;   
      transform: translate(225%, 45%);  
   }

   .sticker {
      width: 18%;
      height: auto;
      position: absolute;  
      z-index: 11;    
      opacity: 0;
   }

   .sticker-1 {
      top: 25%;
      left: 10%;
      transform: rotate(-10deg);
   }

   .sticker-2 {
      top: 20%;
      right: 10%;
      transform: rotate(8deg);
   }
   
   .sticker-3 {
      bottom: 25%;
      left: 20%;
      transform: rotate(12deg);
   }
   
   .sticker-4 {
      bottom: 10%;
      right: 20%;
      transform: rotate(-20deg);
   }
   
   .sticker-5 {
      top: 5%;
      left: 30%;
      transform: rotate(18deg);
   }   
 }

 //phone & small tablet portrait
@media screen and (max-width: 47.5em) {
  .section-1-container {

     .sticker {
      width: 36%;
     }

     .intro-title {
        width: 95%;
     }

     .title-leg {
      width: 33%;
     }

     .title-left {
      width: 42%;
     }

     .title-right {
      width: 39%;
     }     
  }
}