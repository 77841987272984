.section-4-container {
   width: 100vw;
   min-height: 100vh;    
   background-color: #000;
   box-sizing: border-box;
   display: flex;
   flex-direction: column;
   overflow: hidden;
   justify-content: center;

   p {
      font-family: 'HypermarketExpW00', sans-serif;
      font-size: 1em;
      color: #ff00ce;
      text-align: center;
      width: 40%;
      align-self: center;
   }      

   .signature {
      width: 20%;         
      height: auto;        
      object-fit: contain;
      align-self: center;
   }         
   
   // .left-container {
   //    box-sizing: border-box;
   //    width: 25vw;
   //    margin-left: 15vw;
   //    height: 100vh;
   //    padding: 10vh 0;
   //    display: flex;      
   //    flex-direction: column;
   //    justify-content: center;
   //    align-content: center;

   //    p {
   //       font-family: 'HypermarketExpW00', sans-serif;
   //       font-size: 1em;
   //       color: #ff00ce;
   //       text-align: center;
   //    }      

   //    .signature {
   //       width: 80%;         
   //       height: auto;        
   //       object-fit: contain;
   //       align-self: center;
   //    }      

   // }

   // .right-container {
   //    box-sizing: border-box;
   //    width: 30vw;
   //    margin-left: 15vw;
   //    display: flex;
   //    flex-direction: column;
   //    justify-content: center;

   //    .emilie {
   //       width: 80%;
   //       margin-bottom: 2em;
   //       height: auto;        
   //       object-fit: contain;
   //    }    

   // }

 }

  //phone & small tablet portrait
// @media screen and (max-width: 47.5em) {

//    .section-4-container {
//       flex-direction: column-reverse;

//       .left-container {
//          width: 100vw;
//          height: auto;
//          padding: 0 15vw;
//          margin: 0;
//          box-sizing: border-box;             
//          margin-bottom: 5vh;  
//       }

//       .right-container {
//          width: 100vw;
//          height: auto;        
//          margin: 0;    
//          align-items: center;         
//          margin-bottom: 5vh;
//       }
//    }

// }


@media screen and (max-width: 76.25em) {
   .section-4-container {

      p {
         width: 45%;
      }      
   
      .signature {
         width: 25%;         
      }   
   
    }
   
 }
 
 @media screen and (max-width: 52.5em) {
   .section-4-container {

      p {
         width: 50%;
      }      
   
      .signature {
         width: 30%;         
      }   
   
    }
   
 }
 
 @media screen and (max-width: 28.5em) {
   .section-4-container {

      p {
         width: 70%;
      }      
   
      .signature {
         width: 35%;         
      }   
   
    }
   
 }